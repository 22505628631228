/*@media (min-width: 768px) {
    .vertical-center {
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}*/
.vertical-center {
    text-align: left;
}

.question-group {
    margin-top: 40px;
}