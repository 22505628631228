.Image-Dots {
    height: auto;
    width: auto;
}

.card-body-image {
    padding: 0px;
}

.card-text {
    text-align: left;
}

@media (min-width: 767px) {
    .Image-Dots {
        min-height: 175px;
    }
}